<template>
  <DataTable
    :total="total"
    :allow-search="true"
    style="font-size: 12px"
    :filter="filter"
    :loading="load"
    @reset="filter = { export: 0, removed: 0 }"
    @Refresh="refresh"
  >
    <template #customTable>
      <b-table
        :sort-desc.sync="filter.sort_direction"
        :items="items"
        responsive
        striped
        :fields="fields"
        primary-key="id"
        show-empty
        :empty-text="$t('Global.empty_text')"
        style="font-size: 11px"
      >
      <template #cell(manual)="data">
          <div v-if="hasPermission('edit_branches')">
            <!-- <feather-icon
              class="pointer"
              icon="SendIcon"
              @click="sendMail(data.item.id)"
            /> -->
            <button
              class="btn btn-primary"
              @click="sendMail(data.item.id,'manual')"
              style="font-size: 8.5px; padding: 6px 3px;"
            >
             Send Mail <i class="feather icon-send"></i>
            </button>
          </div>
        </template>
      <template #cell(automatic)="data">
          <div v-if="hasPermission('edit_branches')">
            <!-- <feather-icon
              class="pointer"
              icon="SendIcon"
              @click="sendMail(data.item.id)"
            /> -->
            <button
              class="btn btn-secondary"
              @click="sendMail(data.item.id,'automatic')"
              style="font-size: 8.5px; padding: 6px 3px;"
            >
             Send Mail <i class="feather icon-send"></i>
            </button>
          </div>
        </template>
        <template #cell(actions)="data">
          <div v-if="hasPermission('edit_branches')">
            <feather-icon
              class="pointer"
              icon="EditIcon"
              @click="
                $router.push({
                  name: 'branches-edit',
                  params: { id: data.item.id },
                })
              "
            />
            <!-- <feather-icon
              class="pointer"
              icon="FileIcon"
              @click="
                $router.push({
                  name: 'branches-report',
                  params: { id: data.item.id },
                })
              "
            /> -->

          </div>
        </template>

      </b-table>
    </template>
  </DataTable>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BCardHeader,
  BCardBody,
  BModal,
  VBModal,
  BPopover,
  BFormGroup,
  BForm,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email } from "@validations";
import DataTable from "@/views/components/table/DataTable";

export default {
  components: {
    DataTable,
    BFormGroup,
    BFormDatepicker,
    BCard,
    vSelect,
    BRow,
    BCol,
    BOverlay,
    BCardHeader,
    BCardBody,
    BForm,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    VBModal,
    BPopover,
    ValidationProvider,
    ValidationObserver,
    localize,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      check: true,
      validtionData: [
        {
          id: 0,
          name: " Valid Cases",
        },
        {
          id: 1,
          name: "Rejected (Auto-Checks)",
        },
      ],
      status: [
        {
          id: "0",
          name: "New",
        },
        {
          id: 1,
          name: "Waitng branch feedback",
        },
        {
          id: 2,
          name: "Updated",
        },
        {
          id: 3,
          name: "Closed",
        },
      ],
      patient_types: [
        {
          id: "I",
          name: "Inpatient",
        },
        {
          id: "O",
          name: "Outpatient",
        },
      ],
      medical_types: [
        {
          id: 2,
          name: "Dental Cases",
        },
        {
          id: 1,
          name: "Eye Cases",
        },
      ],
      lookupModules: {
        faculties: true,
        bylaws: true,
        programs: true,
      },
      allLookups: null,
      dialog: false,
      filter: { removed: 0 },
      ruleForm: {
        faculties_ids: [],
      },
      user_id: null,
      user_name: null,
    };
  },
  props: {
    logs: {
      type: Boolean,
      default: false,
    },

    statis: false,
  },

  computed: {
    ...mapGetters({
      items: "branches/items",
      total: "branches/total",
      load: "branches/load",
      lookups: "branches/lookups",
    }),
    //   lookups(){

    //   let lookups =  [ {id: 25, name: "MAGRABI Hospital & - Makkah", code: "25", type: null}];
    //   return lookups

    //   },

    title() {
      if (this.user_name) {
        return (
          this.$t("Global.userAccessControl") + " ( " + this.user_name + " )"
        );
      } else {
        return this.$t("Global.userAccessControl");
      }
    },
    fields() {
      let fields = [
        {
          key: "name",
          label: "Branch Name",
          sortable: true,
        },
        {
          key: "code",
          label: "Branch Code",
          sortable: true,
        },
        {
          key: "managrial",
          label: "Managerial Email",
          sortable: true,
        },
        {
          key: "financial",
          label: " Financial Email",
          sortable: true,
        },
        {
          key: "medical",
          label: "Medical Email",
          sortable: true,
        },
        {
          key: "manual",
          label: "Manual Report",
          thClass: "customAction",
          tdClass: "customWidth",
        },
        {
          key: "automatic",
          label: "Automatic Report",
          // thClass: "customAction",
          tdClass: "customWidth",
        },
        {
          key: "actions",
          thClass: "customAction",
          tdClass: "customWidth",
        },
      ];
      if (this.logs == true) {
        fields = [
          {
            key: "name",
            label: "Branch Name",
            sortable: true,
          },
        ];
      }
      return fields;
    },
  },
  methods: {

    ...mapActions('branches', ['sendMail']),

    sendMail(branchId,type){
      console.log(type);
      this.$store.dispatch('branches/sendMail',{branchId,type})
      .then(response=>{
        console.log("hello",response);
        this.$swal({
            icon: "success",
            title: this.$t("Global.SendEmail"),
            showConfirmButton: false,
            timer: 1500,
          });
      })
      .catch(error=>{
        console.log("err",error);
      })

    },
    exportManualPdf(branchId) {
      console.log("sss",branchId);
      // Open a new window or tab with the PDF export route

      window.open(`/api/manual/pdf/${branchId}`, '_blank');
    },    
    exportAutomaticPdf(branchId) {
      console.log("sss",branchId);
      // Open a new window or tab with the PDF export route
      window.open(`/api/pdf/${branchId}`, '_blank');

    },
    ...mapActions({
      getLookups: "app/GET_LOOKUPS",
      setAccessControl: "users/setAccessControl",
    }),
    fetchLookups() {
      this.getLookups(this.lookupModules).then((data) => {
        this.allLookups = data.success;
      });
    },

    openDialog(item) {
      this.user_id = item.id;
      this.user_name = item.name;
      this.ruleForm.faculties_ids = item?.access?.faculties_ids;
      this.dialog = true;
    },
    open(id) {
      window.open(
        this.$router.resolve({ name: "user-edit", params: { id } }).href,
        "_blank"
      );
    },
    refresh(query) {
      query = { ...query, type: this.type };

      this.$store.dispatch("branches/branches", { query });
    },
    submit() {
      this.$refs.simpleRules
        .validate()
        .then((success) => {
          if (success) {
            const payload = {
              query: { faculties_ids: this.ruleForm.faculties_ids },
              id: this.user_id,
            };
            this.setAccessControl(payload)
              .then((response) => {
                this.$swal({
                  icon: "success",
                  text: `${this.$t("Global.successMessage")}`,
                  showConfirmButton: false,
                  timer: 2000,
                });
                this.refresh();
                this.dialog = false;
                this.user_id = null;
                this.user_name = null;
              })
              .catch((error) => {
                // this.$toast({
                //   component: ToastificationContent,
                //   position: 'top-right',
                //   props: {
                //     title: 'Error',
                //     variant: 'danger',
                //     text: `${error || $t('Global.errorMessage')}`,
                //   },
                // })
                this.dialog = false;
                this.user_id = null;
                this.user_name = null;
              });
          }
        })
        .catch((_) => {
          const query = {
            payload: { faculties_ids: this.ruleForm.faculties_ids },
            id: this.user_id,
          };
          this.setAccessControl({ query })
            .then((response) => {
              this.$swal({
                icon: "success",
                text: `${this.$t("Global.successMessage")}`,
                showConfirmButton: false,
                timer: 2000,
              });
              this.refresh();
              this.dialog = false;
              this.user_id = null;
            })
            .catch((error) => {
              // this.$toast({
              //   component: ToastificationContent,
              //   position: 'top-right',
              //   props: {
              //     title: 'Error',
              //     variant: 'danger',
              //     text: `${error || $t('Global.errorMessage')}`,
              //   },
              // })
              this.dialog = false;
              this.user_id = null;
            });
        });
    },
    remove(userId) {
      this.$swal({
        title: `${this.$t("Global.deleteTitle")}`,
        text: `${this.$t("Global.deleteText")}`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: `${this.$t("Global.cancel")}`,
        confirmButtonText: `${this.$t("Global.deleteBtn")}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("users/remove", userId).then((_) => {
            this.refresh();
            this.$swal({
              icon: "success",
              text: this.$t("Global.deletedUser"),
              showConfirmButton: false,
              timer: 1500,
            });
          });
        }
      });
    },

    open_role(id) {
      window.open(
        this.$router.resolve({ name: "user-role", params: { id: id } }).href,
        "_blank"
      );
    },
    restore(userId) {
      this.$swal({
        title: `${this.$t("Global.deleteTitle")}`,
        text: "You Will Activate User!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: `${this.$t("Global.cancel")}`,
        confirmButtonText: "Yes, Activate!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("users/restore", userId).then((_) => {
            this.refresh();
            this.$swal({
              icon: "success",
              title: "Activated!",
              text: "User has been Activated.",
              showConfirmButton: false,
              timer: 1500,
            });
          });
        }
      });
    },
  },
  mounted() {
    // this.fetchLookups();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
th {
  color: blue;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
